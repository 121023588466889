import { createAsyncThunk } from '@reduxjs/toolkit'
import { privateAPI, publicAPI } from './api/fetch'
import { URL } from './api/urls'
import { StateSchema } from '../app/store'
import {
  AuthProfile,
  filterConfirmEmailCode,
  IFriend,
  setError,
  setFriends,
  setHashCode,
  setInviteHash,
  setIsLoadingFriends,
  setStep,
  setToken,
  setType,
  setWorkspaceUserHash,
} from '../state/slices/login'
import { getDomainFromEmail, setCookieAccountToken } from './lib/functions'
import { Cookies } from 'react-cookie'
import { EXPIRED_CODE_TIME_MS, SINGLE_THEMES } from './const/constants'
import { config } from '@clout-team/web-components'
import { langConstants } from './const/lang'

const cookies = new Cookies()

export interface IConfirmCode {
  email: string
  hash_invite?: string
  code: string
}

export const requestCodeByEmail = createAsyncThunk<any, any, any>(
  'login/requestCodeByEmail',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as StateSchema
      if (state.login.confirmEmailByCode?.[data.email]) {
        const isExpired =
          Date.now() - state.login.confirmEmailByCode?.[data.email] > EXPIRED_CODE_TIME_MS

        if (isExpired) {
          thunkAPI.dispatch(filterConfirmEmailCode(data.email))
        } else {
          return thunkAPI.fulfillWithValue({
            response: {
              send: true,
            },
          })
        }
      }

      return await privateAPI('post', `${URL.API}/login/code`, {
        email: data.email,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const checkCodeByEmail = createAsyncThunk<
  any,
  any,
  {
    state: StateSchema
  }
>('login/checkCodeByEmail', async (data, thunkAPI) => {
  const props: IConfirmCode = {
    email: data.email,
    code: data.code,
  }

  if (data.hash_invite) {
    props.hash_invite = data.hash_invite
  }

  try {
    const result = await privateAPI('put', `${URL.API}/login/code`, props)
    const response = result.response
    if (response.status >= 400) {
      return thunkAPI.rejectWithValue(response.data)
    }
    return result
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const confirmCodeByEmail = createAsyncThunk<
  any,
  any,
  {
    state: StateSchema
  }
>('login/confirmCodeByEmail', async (result, thunkAPI) => {
  const response = result.response

  // debugger

  const {
    login: { type, workspaceUserHash, profile },
  } = thunkAPI.getState()

  // если мы создаем новый аккаунт при переходе из данных кук

  if (type === 'ADD_NEW_ACC') {
    thunkAPI.dispatch(setHashCode(response.hash_code))
    thunkAPI.dispatch(setStep('DOMAIN'))
    thunkAPI.dispatch(setType('REG'))
    
    return { response: undefined }
  }

  // если мы создаем новый аккаунт с этой почтой
  if (
    response &&
    response.profile?.length === 0 &&
    type !== 'INVITE_DOMAIN' &&
    type !== 'INVITE_LINK' &&
    response.invite?.length === 0
  ) {
    thunkAPI.dispatch(setHashCode(response.hash_code))
    thunkAPI.dispatch(setStep('NO_SPACES'))

    return { response: undefined }
  }

  // вход в новое рабочее пространство
  if (workspaceUserHash) {
    // alert('workspaceUserHash')
    thunkAPI.dispatch(setHashCode(response.hash_code))
    // если это приглашенный который не заполнил профиль
    const account = profile.find(
      (acc: any) => acc.hashInvite && acc.hash === workspaceUserHash,
    )
    const hashInvite = account?.hash_invite
    if (hashInvite) {
      thunkAPI.dispatch(setInviteHash(hashInvite))
    }

    const activeProfile = profile.find(
      (acc: any) => acc.hash === workspaceUserHash,
    );

    thunkAPI.dispatch(
      getWorkspaceToken({ hash_code: response.hash_code, user_hash: workspaceUserHash, status: activeProfile?.status }),
    )
    
    thunkAPI.dispatch(setWorkspaceUserHash(null))

    return { response: undefined }
  }

  return result
})

export const getWorkspaceToken = createAsyncThunk<any, any, any>(
  'login/getWorkspaceToken',
  async (data, thunkAPI) => {
    try {
      // alert('getWorkspaceToken')
      return await privateAPI('post', `${URL.API}/login/${data.hash_code}/auth`, {
        user_hash: data.user_hash,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const getHashcodeInvite = createAsyncThunk<any, any, any>(
  'login/getHashcodeInvite',
  async (data, thunkApi) => {
    try {
      // для join из данных кук
      if (!data.hash_registration) {
        const res = data.token
          ? await publicAPI(
              'post',
              `${URL.API}/login/invite/${data.hash_code}`,
              {},
              data.token,
            )
          : await privateAPI(
              'post',
              `${URL.API}/login/invite/${data.hash_code}`,
              {},
              cookies.get('token'),
            )
        if (res.response) {
          thunkApi.dispatch(inviteLinkReg({hash: res?.response?.code}))

          return {
            response: {
              code: res.response.code,
              avatar: res.response.company?.avatar || '',
            },
          }
        }
        return {}
      } else {
        const res = await privateAPI(
          'post',
          `${URL.API}/login/${data.hash_registration}/invite/${data.hash_code}`,
          {},
        )

        thunkApi.dispatch(inviteLinkReg({hash: res?.response?.code}))

        return res
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const checkDomainIsValid = createAsyncThunk<any, any, any>(
  'login/checkDomainIsValid',
  async (data, thunkApi) => {
    try {
      return await privateAPI(
        'get',
        `${URL.API}/login/${data.hash_code}/domain?domain=${data.domain}`,
        {},
      )
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const createNewAccount = createAsyncThunk<any, any, any>(
  'login/createNewAccount',
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(setStep('DOMAIN'));
      thunkApi.dispatch(setType('REG'));
      // const lang = cookies.get('lang') ?? 'ru'
      // // @ts-ignore
      // const langConst = langConstants[lang]
      // // для создания из данных кук
      // if (!data.hash_code) {
      //   return await privateAPI(
      //     'post',
      //     `${URL.API}/login/create`,
      //     {lang: langConst},
      //     cookies.get('token'),
      //   )
      // } else {
      //   return await privateAPI('post', `${URL.API}/login/${data.hash_code}/create`, {
      //     individual: false,
      //     lang: langConst,
      //   })
      // }
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const fillProfileInformation = createAsyncThunk<any, any, any>(
  'login/fillProfileInformation',
  async (data, thunkApi) => {
    try {
      const lang = cookies.get('lang') ?? 'ru'
      // @ts-ignore
      const langConst = langConstants[lang]
      return await privateAPI(
        'put',
        `${URL.API}/invite/${data.hash}?name=${data.name}&surname=${data.surname}&avatar_hash=${data.avatar_id}&birthday=${data.birthday}&who_see_birthday=ALL&city_id=${data.city_id}&lang=${langConst}`,
        {},
      )
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const fillCompanyProfile = createAsyncThunk<any, any, any>(
  'login/fillCompanyProfile',
  async (data, thunkApi) => {
    try {
      const lang = cookies.get('lang') ?? 'ru'
      // @ts-ignore
      const langConst = langConstants[lang]
      const res = await privateAPI(
        'post',
        `${URL.API}/login/${data.hash_code}/new`,
        {
          domain: data.domain,
          description: '',
          lang: langConst,
        },
      )

      thunkApi.dispatch(inviteLinkReg({hash: res?.response?.code}))

      return res;
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const inviteLinkReg = createAsyncThunk<any, any, any>(
  'login/inviteLinkReg',
  async (data, thunkAPI) => {
    try {
      return privateAPI('get', `${URL.API}/invite/${data.hash}`, {})
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const approvedDomains = createAsyncThunk<any, any, any>(
  'login/approvedDomains',
  async (hash, thunkApi) => {
    try {
      await regFinish(hash, thunkApi)
    } catch (e) {
      return thunkApi.rejectWithValue(e)
    }
  },
)

export const regFinish = async (hash: string, thunkAPI: any) => {
  const {
    login: {
      type,
      is_domain_invite,
      is_my_domain_corp,
      mail,
      token,
      corpDomains: invitedCorpDomains,
    },
  } = thunkAPI.getState() as StateSchema

  debugger

  if (type && type === 'REG') {
    if (token) {

      try {
        // отправим метрику - цель
        if (window.location.host === 'auth.lenzaos.com')
          // @ts-ignore
          ym(91782041, 'reachGoal', 'success_auth')
      } catch (e) {
        console.log('Не удалось отправить цель в метрику')
      }

      const corpDomains = is_domain_invite ? [...invitedCorpDomains] : []
      if (is_my_domain_corp && mail) corpDomains.push(getDomainFromEmail(mail))
      if (corpDomains.length > 0) {
        thunkAPI.dispatch(
          sendDomainsList({ domains: [...corpDomains], token }),
        )
      }
    }
  }
}

export const inviteUsers = createAsyncThunk<any, any, any>(
  'login/inviteUsers',
  async (data, thunkAPI) => {
    try {
      const res = await privateAPI(
        'post',
        `${URL.API}/user/invite`,
        {
          email: [...data.emails],
          status: 'PARTICIPANT',
        },
      )

      if (!config.IS_MOBILE) await regFinish(data.hash_registration, thunkAPI)

      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const inviteAdmins = createAsyncThunk<any, any, any>(
  'login/inviteAdmins',
  async (data, thunkAPI) => {
    try {
      return 
      privateAPI(
        'put',
        `${URL.API}/login/create/${data.hash_registration}/invite_admin?emails=string&skip=true`,
        {},
      )
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const createInviteLink = createAsyncThunk<any, any, any>(
  'login/createInviteLink',
  async (data, thunkAPI) => {
    try {
      const {login: {token}} = thunkAPI.getState() as StateSchema
      if (token) {
        return privateAPI(
          'post',
          `${URL.API}/biz.inviteInCompanyByLink`,
          {count: data.count},
          token
        )
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const skipInviteUsers = createAsyncThunk<any, any, any>(
  'login/skipInviteUsers',
  async (data, thunkAPI) => {
    try {
      debugger
      const res = {response: true}
      // await privateAPI(
      //   'put',
      //   `${URL.API}/login/create/${data.hash_registration}/invite_user?emails=string&skip=true`,
      //   {},
      // )

      await regFinish(data.hash_registration, thunkAPI)

      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const inviteLinkInfo = createAsyncThunk<any, any, any>(
  'login/inviteLinkInfo',
  async (data, thunkAPI) => {
    try {
      return privateAPI('get', `${URL.API}/invite/${data.hash}`, {})
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

interface FetchFriendsByTokenArg {
  token: string
  profile: IFriend
}

export const fetchFriendsByToken = createAsyncThunk<any, FetchFriendsByTokenArg>(
  'login/fetchFriendsByToken',
  async (arg, thunkAPI) => {
    const { token, profile } = arg

    const { getState } = thunkAPI

    const state: any = getState()

    try {
      const { response } = await publicAPI('get', `${URL.API}/user`, {}, token)

      if (response && response.created && Array.isArray(response.created)) {
        thunkAPI.dispatch(setIsLoadingFriends(false))

        const users = response.created.filter(
          (contact: IFriend) =>
            (contact.contact_status === 'NONE' || contact.contact_status === 'FRIEND') &&
            // contact.contact_type === 'COMPANY' &&
            // contact.email !== state.login?.mail &&
            contact.domain !== 'bot' &&
            contact.status === 'ACTIVE' &&
            profile?.hash !== contact.hash,
        )

        thunkAPI.dispatch(setFriends(users))
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const userFalseInviteFillProfile = createAsyncThunk<any, any, any>(
  'login/userFalseInviteFillProfile',
  async (data, thunkAPI) => {
    try {
      const res = await privateAPI('put', `${URL.API}/invite/${data.hash_code}/${data.hash}`, {},)

      await thunkAPI.dispatch(inviteLinkReg({hash: res?.response?.code}), )
      
      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const userTrueInviteFillProfile = createAsyncThunk<any, any, any>(
  'login/userTrueInviteFillProfile',
  async (data, thunkAPI) => {
    try {
      const lang = cookies.get('lang') ?? 'ru'
      // @ts-ignore
      const langConst = langConstants[lang]
      const res = await privateAPI(
        'put',
        `${URL.API}/invite/${data.hash}?name=${data.name}&surname=${data.surname}&avatar_hash=${data.avatar_id}&birthday=${data.birthday}&who_see_birthday=ALL&city_id=${data.city_id}&lang=${langConst}`,
        {},
      )

      await thunkAPI.dispatch(
        fetchFriendsByToken({
          token: res?.response?.token,
          profile: res?.response?.profile,
        }),
      )
      return res
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const sendMessageAndAddToContacts = createAsyncThunk<any, any, any>(
  'login/sendMessageAndAddToContacts',
  async (data, thunkAPI) => {
    try {
      return await publicAPI(
        'post',
        `${URL.API}/user/add`,
        {
          ids: data.ids,
          message: data.message,
        },
        data.token,
      )
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const sendDomainsList = createAsyncThunk<any, any, any>(
  'login/sendDomains',
  async (data, thunkAPI) => {
    try {
      debugger
      return await publicAPI(
        'post',
        `${URL.API}/biz/invite/domain`,
        {
          domains: data.domains,
        },
        data.token,
      )
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const getPublicDomainInfo = createAsyncThunk<any, any, any>(
  'login/getPublicDomainInfo',
  async (data, thunkAPI) => {
    try {
      return await privateAPI('get', `${URL.API}/company/info/${data.name}`, {})
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  },
)

export const getLoginList = (
  token = '',
): Promise<{
  response: {
    invite: AuthProfile[]
    profile: AuthProfile[]
  }
}> => {
  return privateAPI('get', `${URL.API}/login/list`, {}, token)
}

export const profileGet = (token: string) => {
  return privateAPI('get', `${URL.API}/profile`, {}, token)
}
