import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/app'
import './app/styles/app.scss'
import '@clout-team/web-components/build/index.css'
import { BrowserRouter } from 'react-router-dom'
import store from './app/store'
import { Provider } from 'react-redux'
import ErrorBoundary from './app/ErrorBoundary/ErrorBoundary'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import jsonData from '../package.json'
import './shared/config/i18n/i18n'
import Loading from './components/Loading/Loading'
import PageRedirect from './components/PageRedirect/PageRedirect'
import { config, isAndroidApp, isIosApp } from '@clout-team/web-components'
import { isMobile } from '@clout-team/helpers'
import { IS_LOCALHOST } from './shared/const/constants'
import { DEBUG_MODE } from './shared/const/localStorageKeys'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d1dba62f58cc42179d761b5a47506a7f@error.lenzaos.com/5',
    release: `${jsonData.version}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}

function isDebugMode () {
  try {
    const debug_mode = localStorage.getItem(DEBUG_MODE)
    return debug_mode === 'develop' || new URL(location?.href)?.searchParams?.has('debug');
  } catch (e) {
    console.log(e)
  }
  return false
}

const { IS_AUTH_MODULE } = config
const showPageRedirect =
  !IS_LOCALHOST && IS_AUTH_MODULE && isMobile() && !isIosApp() && !isAndroidApp() && !isDebugMode()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter basename={'/'}>
    <Suspense fallback={<Loading showText={false} />}>
      {showPageRedirect && <PageRedirect />}
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </Suspense>
  </BrowserRouter>,
)
