import {
  InviteUsersInCompany,
  Layout,
  SuccessInviteModal,
  Text,
  useCreateLinkInvite,
} from '@clout-team/web-components'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import {
  approvedDomains,
  createInviteLink,
  inviteAdmins,
  inviteUsers,
  skipInviteUsers,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { binarySearch } from '../../shared/const/emails'
import { useLoginActions } from '../../state/slices/login'
import './InviteUsers.scss'
import {
  getDomainFromEmail,
  isCorpEmail,
  isInnerIframe,
} from '../../shared/lib/functions'
import { isMobile } from '@clout-team/helpers'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import { Company, Mail, ShareAndroid } from '@clout-team/icons/react'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import CopyLink from './CopyLink/CopyLink'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const InviteUsersMob: React.FC = () => {
  const { t } = useTranslation()

  const { setCorpDomains, setStep, setInviteByEmail } = useLoginActions()

  const [items, setItems] = useState<any[]>([])
  const dispatch = useAppDispatch()

  const hashCode = useAppSelector((state) => state.login.hash_code)
  const inviteLink = useAppSelector((state) => state.login.invite_link)
  const companyName = useAppSelector((state) => state.login.company_name)
  const corpDomains = useAppSelector((state) => state.login.corpDomains)
  const currentAccountMail = useAppSelector((state) => state.login.mail ?? '')
  const inviteByEmail = useAppSelector((state) => state.login.isInviteByEmail)

  const [isInviteUsersMenuOpen, setInviteUsersMenuOpen] = useState(false)
  const [isOpenSuccessInviteModal, showSuccessInviteModal] = useState(false)
  const layoutRef = useRef<HTMLDivElement | null>(null)

  const isValidate = (el: any) => el.isValid && !el.isExists

  const { shareBetweenApps } = useCreateLinkInvite({
    domain: companyName,
  })

  const filterValidCorpDomains = (items: any[]) => {
    const validItems = items.map((el) => {
      if (isValidate(el)) {
        return getDomainFromEmail(el.title)
      }
      return
    })

    const uniqueItems = validItems
      .filter((element, index) => {
        return validItems.indexOf(element) === index
      })
      .filter((el) => {
        const res = binarySearch(el)
        return res === -1 && el !== undefined
      })

    return uniqueItems
  }

  const onSkip = async () => {
    if (corpDomains.length > 0 || isCorpEmail(currentAccountMail)) {
      setStep('APPROVE_DOMAINS')
    } else {
      await dispatch(inviteAdmins({ skip: true, hash_registration: hashCode }))

      if (inviteByEmail) {
        await dispatch(approvedDomains(hashCode))
      } else {
        await dispatch(skipInviteUsers({ hash_registration: hashCode }))
      }
    }
  }

  const onCopyLink = () => {
    if (inviteLink) {
      shareBetweenApps(inviteLink)
    }
  }

  useEffect(() => {
    dispatch(createInviteLink({ count: 12 }))
    
    if (!layoutRef.current) return

    const btnTitleArr = layoutRef.current.getElementsByClassName('btn__title')
    if (!(btnTitleArr[0] instanceof HTMLElement) || !(btnTitleArr[1] instanceof HTMLElement)) return
    
    const width0 = getComputedStyle(btnTitleArr[0]).width
    const width1 = getComputedStyle(btnTitleArr[1]).width
    if (parseInt(width0) > parseInt(width1)) {
      btnTitleArr[1].style.width = width0
    } else {
      btnTitleArr[0].style.width = width1
    }
  }, [])

  const onContinueButton = async () => {
    const validMails = items.map((el) => {
      if (isValidate(el)) {
        return el.title
      }
      return
    })

    dispatch(inviteUsers({ emails: validMails, skip: false, hash_registration: hashCode }))

    if (validMails.length) {
      setInviteByEmail(true)
    }

    showSuccessInviteModal(true)

    const corpDomains = filterValidCorpDomains(items)
    setCorpDomains(corpDomains)

    return false
  }

  const isHasBackButton = isInnerIframe() && cookies.get('token')

  const onBack = () => {
    setStep('WORKSPACE')
  }

  return (
    <div className={'inu_invite'}>
      <MobileHeader
        title={t('invite-users.title')}
        onClose={isHasBackButton ? onBack : undefined}
        subtitle={
          <Layout className={'inu_invite_header_sub'}>
            <Company width={16} height={16} color='var(--ui-text-80)' />
            {companyName}
          </Layout>
        }
      />

      <Layout gap={'md'} className={'inu_invite_start_body'} vertical ref={layoutRef}>
        <Text type={'secondary'}>{t('invite-users.label')}</Text>
        <CopyLink
          copyTarget={inviteLink!}
          onCopy={onCopyLink}
          trigger={
            <ButtonUI
              fullWidth
              className={classNames(
                'invite-company__button',
                'inu_invite_start_copy_link',
              )}
              onClick={onCopyLink}
              iconLeft={<ShareAndroid width={24} height={24} />}
              size={'xlg'}
            >
              {t('invite-users.share-link')}
            </ButtonUI>
          }
        />
        <ButtonUI
          className={classNames('invite-company__button')}
          iconLeft={<Mail width={24} height={24} />}
          color='white'
          size={'xlg'}
          onClick={() => setInviteUsersMenuOpen(true)}
        >
          {t('invite-users.add-by-email')}
        </ButtonUI>
      </Layout>

      <Layout className={'inu_invite_footer'}>
        <ButtonUI fullWidth onClick={onSkip} color='white' size={'xlg'}>
          {t('invite-users.invite-later')}
        </ButtonUI>
      </Layout>

      {isInviteUsersMenuOpen && (
        <InviteUsersInCompany
          isUserInvite={false}
          isSuccess={false}
          domain={companyName}
          withoutConfirmationModal
          version={1}
          handleContinueButton={onContinueButton}
          onModalClose={() => {
            if (isMobile()) setInviteUsersMenuOpen(false)
          }}
          onChangeItems={(val: any) => setItems(val)}
          hashRegistration={hashCode}
        />
      )}

      {isOpenSuccessInviteModal && (
        <SuccessInviteModal data={items} onClose={() => showSuccessInviteModal(false)} showIsValid />
      )}
    </div>
  )
}

export default InviteUsersMob
