import React, { useEffect, useState } from 'react'
import './Login.scss'
import { useLoginActions } from '../../state/slices/login'
import { requestCodeByEmail } from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import {
  ChatTagsRowInputEmail,
  config,
  Text,
  useAnyKeyboard,
} from '@clout-team/web-components'
import Privacy from '../Privacy/Privacy'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'
import { Info, Mail } from '@clout-team/icons/react'
import { getIsHasProfiles } from '../../state/selectors/getIsHasProfiles'
import { WrapperMobilePage } from '../../shared/ui/WrapperMobilePage/WrapperMobilePage'
import classNames from 'classnames'
import { isValidEmail } from '../../shared/lib/isValidEmail'

interface MailItem {
  icon: React.ReactElement<any>
  title: string
  id: number
  isValid: boolean
}

const CODE_TIMEOUT = 'codeTimeout'

const LoginWeb = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const loginActions = useLoginActions()
  const isLoading = useAppSelector((state) => state.login.is_loading)

  const isAuth = useAppSelector(getIsHasProfiles)
  const error = useAppSelector((state) => state.login.error)

  const [mails, setMails] = useState<MailItem[]>([])
  const [search, setSearch] = useState<string>('')

  const onContinue = () => {
    if (mails.length) {
      const mail = mails[0]
      const dataStr = localStorage.getItem(CODE_TIMEOUT)
      loginActions.setMail(mail.title)
      if (dataStr) {
        try {
          const data = JSON.parse(dataStr)
          if (data.email === mail.title) {
  
            if (data.confirmTimeout > Date.now()) {
              loginActions.setStep('CODE')
              return
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
      loginActions.setIsLoading(true)
      dispatch(requestCodeByEmail({ email: mail.title }))
    }
  }

  const isMobileKeyboardActive = useAnyKeyboard()

  const addValue = async (value: string[], isDeleteItemHandler?: boolean) => {
    if (Array.isArray(value)) {
      setMails((prev) => {
        let newItems = [...prev]
        if (isDeleteItemHandler) {
          newItems = []
        }

        value.map((el, i) => {
          const isValid = isValidEmail(el.trim()) && (i === 0 || !newItems?.find(item => item?.isValid)?.isValid)
          if (prev.find((v) => v.title === el) && !isDeleteItemHandler) return
          if (el === '') return

          const elem: MailItem = {
            icon: isValid ? (
              <Mail color='var(--ui-text-80)' />
            ) : (
              <Info color='var(--ui-text-80)' />
            ),
            title: el,
            id: Date.now() + i,
            isValid,
          }

          newItems.push(elem)
        })

        return newItems
      })
    }
    setSearch('')
  }

  const addMail = (str: string) => {
    str = str.trim()

    if (str.length > 0) {
      const valuesArr = str.split(' ')
      addValue(valuesArr)
    }
  }

  const isCanContinue = mails.length === 1 && mails[0].isValid

  const onBackToPage = () => {
    if (isAuth) {
      loginActions.setStep('WORKSPACE')
    } else {
      loginActions.setStep('PROMO')
    }
  }

  return (
    <WrapperMobilePage className={'log_login'}>
      <MobileHeader title={t('login.email.title')} onClose={onBackToPage} />

      <div className='log_body'>
        <ChatTagsRowInputEmail<MailItem>
          errorWrapperClassName={'log_body_input_error_container'}
          hasInput={mails.length === 0}
          inputSize={config.IS_MOBILE ? (mails.length == 0 ? 'sm' : 'md') : 'md'}
          search={search}
          items={mails}
          setItems={setMails}
          addMail={addMail}
          showInvalidItems
          isDisabledOverlayContextMenu
          maxSelectedItems={1}
          addValue={addValue}
          placeholder={'example@mail.com'}
          inputStyle={'filled'}
          setSearch={setSearch}
          size={'24'}
          align={'left'}
          onDeleteItem={deletedItemId => addValue(mails?.filter(el => el?.id !== deletedItemId)?.map(el => el?.title), true)}
        />

        <Text className={'log_body_description'}>{t('login.input.description')}</Text>
      </div>

      <footer className='log_footer'>
        {error !== null && <p className={'log_login__error'}>{t(error)}</p>}
        <ButtonUI
          loading={isLoading}
          size={'xlg'}
          disabled={!isCanContinue}
          fullWidth
          onClick={onContinue}
        >
          {t('auth.new.main.continue_btn')}
        </ButtonUI>
        <Privacy
          align={'left'}
          className={classNames('log_privacy', {
            log_privacy_keyboard_active: isMobileKeyboardActive,
          })}
        />
      </footer>
    </WrapperMobilePage>
  )
}

export default LoginWeb
