import { Button, Layout, Loading, Switch, Text } from '@clout-team/web-components'
import React, { useEffect, useMemo, useState } from 'react'
import { sendMessageAndAddToContacts } from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { IFriend, useLoginActions } from '../../state/slices/login'
import './AddFriends.scss'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import { AppsList, Cards } from '@clout-team/icons/react'
import CardContact from '../CardContact/CardContact'
import { capitalize, isInnerIframe } from '../../shared/lib/functions'
import classNames from 'classnames'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { SendFirstMessageModal } from '../SendMessageModal/SendMessageModal'
import { Cookies } from 'react-cookie'

interface IContacts extends IFriend {
  chosen?: boolean
}

const cookies = new Cookies()

export const AddFriendsMob: React.FC = () => {
  const { t } = useTranslation()

  const { setStep } = useLoginActions()
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.login.token)
  const companyName = useAppSelector((state) => state.login.company_name)
  const friends = useAppSelector(
    (state) => state.login.friends as (IFriend & { chosen: boolean })[],
  )

  const isLoadingFriends: boolean = useAppSelector(
    (state) => state.login.is_loading_friends,
  )

  const [contacts, setContacts] = useState<IContacts[]>(friends)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [isOpenSendFirstMessage, showSendFirstMessage] = useState<boolean>(false)

  const [typeContactView, setTypeContactView] = useState<'list' | 'card'>('list')

  const onSkip = () => {
    setStep('FINISH')
  }

  const onCardClick = (el: IContacts) => {
    if (el.is_friend) return

    setContacts((prev) => [
      ...prev.map((contact: IContacts) => {
        if (allSelected) {
          setAllSelected(false)
        }
        if (contact.hash === el.hash) {
          return { ...contact, chosen: !contact.chosen }
        }
        return contact
      }),
    ])
  }

  const onSwitchContactView = () => {
    setTypeContactView((type) => (type === 'card' ? 'list' : 'card'))
  }

  const onChoseAllContacts = () => {
    if (allSelected) {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: false,
          }
        }),
      ])
      setAllSelected(false)
      return
    } else {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: !el.is_friend,
          }
        }),
      ])
      setAllSelected(true)
    }
  }

  useEffect(() => {
    setContacts(
      friends.map((friend) => {
        if (allSelected && friend && typeof friend === 'object')
          return {
            ...friend,
            chosen: true,
          }
        return friend
      }),
    )
  }, [friends])

  const countSelectedContacts = useMemo(
    () => contacts.filter((contact) => contact.chosen).length,
    [contacts],
  )

  const onAddContacts = (text: string) => {
    if (countSelectedContacts !== 0) {
      const chosenContactsHashes = contacts
        .filter((el) => {
          if (el.chosen) {
            return el
          }
        })
        .map((el) => {
          return el.hash
        })

      dispatch(
        sendMessageAndAddToContacts({
          ids: chosenContactsHashes,
          message: text,
          token: token,
        }),
      )
    }
  }

  const isHasBackButton = isInnerIframe() && cookies.get('token')

  const onBack = () => {
    setStep('WORKSPACE')
  }

  return (
    <div className='add_friends_mob'>
      <MobileHeader
        onClose={isHasBackButton ? onBack : undefined}
        rightButton={{
          icon: typeContactView === 'card' ? <AppsList /> : <Cards />,
          onClick: onSwitchContactView,
        }}
        title={`${t('add-friends.title')} 👥`}
      />

      <Layout className='add_friends_mob__filter' vertical gap='lg'>
        <Text type={'secondary'}>
          {t('add-friends.description')} {companyName}
        </Text>
        <Button
          color={'white'}
          className='add_friends_mob__select-all'
          onClick={onChoseAllContacts}
        >
          <Text size={'body-mobile'}>{t('add-friends.select-all')}</Text>
          <Switch checked={allSelected} size={'lg'} />
        </Button>
      </Layout>

      <Layout
        className={classNames('add_friends_mob__contact-list', {
          'add_friends_mob__contact-list_list-view': typeContactView === 'list',
        })}
        gap='sm'
      >
        {isLoadingFriends ? (
          <Layout
            isAlignCenter
            className={'add_friends_mob__loader'}
            justifyContent='center'
          >
            <Loading isNewDesign color={'white'} size={'md'} />
          </Layout>
        ) : (
          contacts.map((contact) => {
            return (
              <CardContact
                typeView={typeContactView}
                key={contact.hash}
                onClick={() => onCardClick(contact)}
                isSelected={contact.chosen}
                title={contact.name + ' ' + contact.surname}
                avatar={contact.avatars?.[0]?.url_720}
                isFriend={contact.is_friend}
                description={capitalize(
                  contact.department ? contact.department.title : contact.domain,
                )}
              />
            )
          })
        )}
      </Layout>

      <Layout className={'add_friends_mob__footer'} vertical gap={'md'}>
        <ButtonUI color='white' size={'xlg'} fullWidth onClick={onSkip}>
          {t('auth.new.friends.skip')}
        </ButtonUI>
        <ButtonUI
          size={'xlg'}
          disabled={countSelectedContacts === 0}
          fullWidth
          onClick={() => showSendFirstMessage(true)}
        >
          {t('add-friends.add')} ({countSelectedContacts})
        </ButtonUI>
      </Layout>

      {isOpenSendFirstMessage && (
        <SendFirstMessageModal
          onClose={() => showSendFirstMessage((prev) => !prev)}
          onSubmit={(text) => onAddContacts(text)}
        />
      )}
    </div>
  )
}
